/** @jsx jsx */
import { animated, useSpring, config } from "react-spring"
import { Container, jsx, Flex, Heading } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "./layout"
import SEO from "./seo"
import { ChildImageSharp } from "../types"
import Hero from "./hero"

type PageProps = {
  data: {
    page: {
      title: string
      slug: string
      excerpt: string
      body: string
      cover: ChildImageSharp
    }
  }
}

const Page = ({ data: { page } }: PageProps) => {
  const titleProps = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: `translate3d(0, 60px, 0)` },
    to: { opacity: 1, transform: `translate3d(0, 0, 0)` },
  })
  const contentProps = useSpring({ config: config.slow, delay: 750, from: { opacity: 0 }, to: { opacity: 1 } })

  const fadeUpProps = useSpring({
    config: config.slow,
    delay: 750,
    from: { opacity: 0, transform: `translate3d(0, 60px, 0)` },
    to: { opacity: 1, transform: `translate3d(0, 0, 0)` },
  })

  const bodyOverCover = false;

  const pageBody = <Container>
  <animated.div style={fadeUpProps}>
    <MDXRenderer>{page.body}</MDXRenderer>
  </animated.div>
</Container>

  return (
    <Layout>
      <SEO title={page.title} description={page.excerpt} pathname={page.slug} />
      <Hero image={page.cover.childImageSharp.fluid} slim color='#ddd'>
        <Flex
          sx={{
            position: `absolute`,
            bottom: 0,
            left: 0,
            right: 0,
            maxWidth: `5xl`,
            margin: `0 auto`,
            padding: 4,
            zIndex: 2,
            flexDirection: `column`,
          }}
        >
          <animated.div style={titleProps}>
            <Heading as="h1" variant="styles.h1">
              {page.title}
            </Heading>
          </animated.div>
          {bodyOverCover ? pageBody : '' }
        </Flex>
      </Hero>
      {bodyOverCover ? '' : pageBody }
    </Layout>
  )
}

export default Page
